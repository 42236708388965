export const releaseNotesVersion = [
  {
    id: 1,
    version: "v1.2.1",
    releaseDate: " 05/13/2023",
  },
  {
    id: 2,
    version: "v1.4.0",
    releaseDate: "10/21/2023",
  },
];
