import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AvatarImg from "../../assets/svg/Handovers/ClientProfile.svg";
import Avatar from "react-avatar-edit";
import * as api from "../../api";
import "./user-info.css";
import Swal from "sweetalert2";

const UserInfo = ({ firstName, lastName, id }) => {
  // const { oktaAuth, authState } = useOktaAuth();
  // const accessToken = authState.accessToken;
  const history = useHistory();
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const userId = id;
  const [greeting, setGreeting] = useState("");
  const [avatarPic, setAvatarPic] = useState(null);
  const [preview, setPreview] = useState("");
  const [picture, setPicture] = useState({
    picture: "",
  });
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const generateGreetings = (currentHour) => {
    if (currentHour >= 0 && currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting("Good Afternoon");
    } else if (currentHour >= 17) {
      setGreeting("Good Evening");
    }
  };

  useEffect(() => {
    if (!authState) {
      // oktaAuth.signOut();
      localStorage.removeItem("auth0-token-storage");
    }
  }, [authState]);

  useEffect(() => {
    generateGreetings(moment().format("HH"));
  }, []);

  const onClose = () => {
    setPreview("");
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 3000000) {
      Swal.fire({
        title: "Error!",
        html: "File is too big",
        icon: "error",
        confirmButtonColor: "#833f84",
      });
      elem.target.value = "";
    }
  };

  useEffect(() => {
    // console.log(preview);
    setPicture({ ...picture, picture: preview });
  }, [preview]);

  const cancelUpdatePhoto = () => {
    if (preview === "" || preview === null) {
      setPreview("");
      setShow(false);
    } else {
      setShow(false);
    }
  };

  const updatePhoto = async () => {
    setLoader(true);
    try {
      await api.updateUserPhoto(picture, userId, accessToken);
      Swal.fire({
        title: "Success!",
        icon: "success",
        html: "Photo updated successfully!",
        confirmButtonColor: "#833f84",
      });
      setLoader(false);
      setShow(false);
    } catch (error) {
      console.log("error updating user photo", error);
      Swal.fire({
        title: "Error!",
        html: "Error Updating Photo",
        icon: "error",
        confirmButtonColor: "#833f84",
      });
      setLoader(false);
      setShow(false);
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.errors?.length > 0 &&
        error?.response?.data?.errors[0]?.message === "Invalid Token"
      ) {
        handleExpiration();
      }
    }
  };

  const getUserPhoto = async () => {
    setLoader(true);
    try {
      const data = await api.getUserPhoto(userId, accessToken);
      // console.log(data.data.data);
      const img = data.data.data;
      setPreview(img);
    } catch (err) {
      console.log("error getting user photo", err);
      if (
        err?.response?.status === 401 &&
        err?.response?.data?.errors?.length > 0 &&
        err?.response?.data?.errors[0]?.message === "Invalid Token"
      ) {
        handleExpiration();
      }
    }
  };

  const handleExpiration = () => {
    localStorage.removeItem("auth0-token-storage");
    localStorage.removeItem("user-claims");
    history.push("/login");
  };

  useEffect(() => {
    getUserPhoto();
  }, []);

  return (
    <div className="text-center pb-5 mt-5">
      <div className="rounded-circle mx-auto avatar d-flex justify-content-center">
        {/* <h2 className="text-light align-self-center">
          {firstName[0].toUpperCase()}
          {lastName[0].toUpperCase()}
        </h2> */}
        {preview ? (
          <img src={preview} alt="" className="avatarImg" />
        ) : (
          <img src={AvatarImg} alt="" className="avatarImg" />
        )}

        <div className="editAvatar align-self-center"></div>
      </div>
      <div
        className="editAvatar d-flex align-self-center align-items-center justify-content-center orange-1"
        style={{ cursor: "pointer" }}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={["fas", "edit"]} style={{ fontSize: "15px" }} />
        <p className="ml-1 orange-1">Edit</p>
      </div>

      <p className="pt-3">
        {greeting},{" "}
        {firstName === "Demo" || firstName === "demo"
          ? firstName.charAt(0).toUpperCase() +
            firstName.slice(1) +
            " " +
            lastName.charAt(0).toUpperCase() +
            lastName.slice(1)
          : firstName.charAt(0).toUpperCase() + firstName.slice(1)}
        !
      </p>
      {/* <div className="d-flex justify-content-around align-content-center orange-bg-1 text-light mx-auto mb-2 rounded-pill w-90">
                <h5 className="mt-1 text-left">Hapi Loved Ones</h5>
                <i className="fa fa-redo bg-light orange-1 rounded-circle p-1 mt-2 h-100"></i>
            </div>
            <div className="btn-group btn-group-toggle orange-bg-2 rounded-pill w-90" data-toggle="buttons">
                <label onClick={() => setActiveRole("super user")} className={`btn rounded-pill text-light w-50 ${activeRole === "super user" ? "orange-bg-1" : ""}`} htmlFor=""> Super user
                    <input type="radio" name="options" id="option1" autoComplete="off" />
                </label>
                <label onClick={() => setActiveRole("partner")} className={`btn rounded-pill text-light w-50 ${activeRole === "partner" ? "orange-bg-1" : ""}`} htmlFor=""> Partner
                    <input type="radio" name="options" id="option2" autoComplete="off" />
                </label>
            </div> */}
      <Modal size="md" show={show} onHide={cancelUpdatePhoto}>
        <Modal.Header closeButton>
          <Modal.Title className="orange-1">Choose a Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-auto">
          <Avatar
            src={avatarPic}
            width={400}
            height={300}
            onCrop={onCrop}
            onClose={onClose}
            onBeforeFileLoad={onBeforeFileLoad}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelUpdatePhoto}>
            Cancel
          </Button>
          <Button variant="primary" onClick={updatePhoto}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserInfo;
