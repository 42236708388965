import React, { useState, useEffect } from "react";
import iso from "iso-3166-1";
import { NavLink } from "react-router-dom";
import moment from "moment";

import AlertsTop from "../reusables/AlertsTop";
import Loader from "../reusables/Loader";
import Swal from "sweetalert2";
import * as api from "../../api";
import { Spinner } from "react-bootstrap";

const RegisterCareHome = () => {
  const [loading, setLoading] = useState(false);
  const [registerSuccessful, setRegisterSuccessful] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [facilitiesMap, setFacilitiesMap] = useState();
  const [errorIndicator, setErrorIndicator] = useState();
  const [countries, setCountries] = useState([]);
  const [practices, setPractices] = useState([]);
  const [signupError, setsignupError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    nickName: "",
    dateOfBirth: moment(new Date()).format("YYYY-MM-DD"),
    gender: "male",
    address: "",
    zipCode: "",
    countryCode: "",
    practice: "64775dcefa713c353e6b522b",
    email: "",
    password: "",
    facility: "",
    clientApp: ["HAPIPARTNERS", "HAPICLIENT", "HAPIA"],
    facilityName: "",
  });
  const [birthday, setBirthday] = useState({
    day: moment().date().toString(),
    month: moment().format("MM"),
    year: moment().year().toString(),
  });

  // useEffect(() => {
  //   if (birthday && birthday !== "") {
  //     const birthDate = moment(birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
  //     setUserInfo({
  //       ...userInfo,
  //       dateOfBirth: birthDate,
  //     });
  //   }
  // }, [birthday]);

  useEffect(() => {
    if (iso.all().length > 0) {
      setCountries(iso.all());
    }
  }, [iso]);

  useEffect(() => {
    if (practices) {
      setUserInfo({
        ...userInfo,
        practice: practices[0]?._id,
      });
    }
  }, [practices]);
  const validateForm = () => {
    let isValid = false;

    if (
      userInfo.firstName === "" ||
      userInfo.middleName === "" ||
      userInfo.lastName === "" ||
      userInfo.nickName === "" ||
      userInfo.dateOfBirth === "" ||
      userInfo.address === "" ||
      userInfo.zipCode === "" ||
      userInfo.countryCode === "" ||
      userInfo.email === "" ||
      userInfo.password === ""
    ) {
      if (userInfo.firstName === "") {
        setErrorIndicator("firstName");
      } else if (userInfo.middleName === "") {
        setErrorIndicator("middleName");
      } else if (userInfo.lastName === "") {
        setErrorIndicator("lastName");
      } else if (userInfo.nickName === "") {
        setErrorIndicator("nickName");
      } else if (userInfo.dateOfBirth === "") {
        setErrorIndicator("dateOfBirth");
      } else if (userInfo.address === "") {
        setErrorIndicator("address");
      } else if (userInfo.zipCode === "") {
        setErrorIndicator("zipCode");
      } else if (userInfo.countryCode === "") {
        setErrorIndicator("countryCode");
      } else if (userInfo.email === "") {
        setErrorIndicator("email");
      } else if (userInfo.password === "") {
        setErrorIndicator("password");
      }
      setErrorMessage("Please fill in all the fields.");
      isValid = false;
    } else if (userInfo.password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setErrorIndicator("password");
      isValid = false;
    } else if (userInfo.password.length <= 8) {
      setErrorMessage("Password needs to be longer than 8 characters.");
      setErrorIndicator("password");
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  useEffect(() => {
    const getAllFacilities = async () => {
      try {
        const res = await api.fetchAllFacilities();
        const facilities = res.data.data.data;

        const facilityInfo = [];
        let map=[];
        facilities.forEach((facility) => {
          facilityInfo.push({
            id: facility.FacilityID,
            longName: facility.FacilityName,
          });
          map[facility.FacilityID] = facility.FacilityName;
        });
        setFacilitiesMap(map)
        setFacilities(facilityInfo);
        // console.log();
      } catch (err) {
        console.error(err);
      }
    };

    getAllFacilities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (validateForm()) {
        console.log(userInfo);
        userInfo.facilityName=facilitiesMap[userInfo.facility];
        const data = await api.signUpCarehome(userInfo);
        if (data) {
          Swal.fire({
            html: "Successfully signed up! An email will be sent to you once the admin approved your request!",
            icon: "success",
            title: "Success",
            confirmButtonColor: "#833f84",
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setsignupError(error.response.data.errors);
    }
  };

  useEffect(() => {
    if (signupError) {
      const errors = signupError.map((err) => {
        return err.message + "\r\n";
      });
      Swal.fire({
        title: "Error!",
        html: errors,
        icon: "error",
        confirmButtonColor: "#833f84",
      });
    }
  }, [signupError]);

  const getPractice = async () => {
    setLoading(true);
    try {
      const data = await api.getPractices();
      const practice = data.data.data;
      setPractices(practice);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPractice();
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "facility") {
      setUserInfo({ ...userInfo, facilityName: facilitiesMap[value] });
      setUserInfo({ ...userInfo, facility: value });
      return;
    }

    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleChangeBirthday = (e) => {
    setBirthday({ ...birthday, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (birthday.day && birthday.month && birthday.year) {
      const combinedDate = moment(
        `${birthday.year}-${birthday.month}-${birthday.day}`,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      setUserInfo({
        ...userInfo,
        dateOfBirth: combinedDate,
      });
    }
  }, [birthday.day, birthday.month, birthday.year]);

  const renderDays = () => {
    const { month, year } = birthday;
    const numDays = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    const days = [];

    for (let i = 1; i <= numDays; i++) {
      const dayValue = i < 10 ? `0${i}` : i;
      days.push(
        <option key={i} value={dayValue}>
          {dayValue}
        </option>
      );
    }
    return days;
  };

  const renderMonths = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      const monthValue = i < 10 ? `0${i}` : i;
      months.push(
        <option key={i} value={monthValue} className="text-center">
          {monthValue}
        </option>
      );
    }
    return months;
  };

  const renderYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1900; i--) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return years;
  };

  if (loading) return <Loader />;
  return (
    <div className="container mt-3" style={{ margin: "150px auto" }}>
      {errorMessage && (
        <AlertsTop text={errorMessage} bsClass="alert alert-danger" />
      )}
      {registerSuccessful && (
        <AlertsTop
          text="Registration was successful you may now login."
          bsClass="alert alert-success"
        />
      )}
      <div className="container p-3 mt-2 bg-light border shadow-sm">
        {successMessage ? (
          <p className="text-center text-success">{successMessage}</p>
        ) : null}
        <form>
          <div className="form-group">
            <p className="text-center lead mb-4">Register Care Home</p>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label htmlFor="firstName">First Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "firstName" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="firstName"
                  value={userInfo.firstName.toLowerCase() ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="middleName">Middle Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "middleName"
                      ? "border border-danger"
                      : ""
                  }`}
                  type="text"
                  name="middleName"
                  value={userInfo.middleName.toLowerCase() ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="lastname">Last Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "lastname" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="lastName"
                  value={userInfo.lastName.toLowerCase() ?? ""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label htmlFor="nickName">Nick Name</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "nickName" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="nickName"
                  value={userInfo.nickName.toLowerCase() ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4" style={{ zIndex: 1 }}>
                <label htmlFor="dateOfBirth">Date Of Birth</label>
                {/* <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "dateOfBirth"
                      ? "border border-danger"
                      : ""
                  }`}
                  type="date"
                  name="dateOfBirth"
                  value={userInfo.dateOfBirth ?? ""}
                /> */}
                <div>
                  <label>
                    <select
                      className={`form-control ${
                        errorIndicator === "dateOfBirth"
                          ? "border border-danger"
                          : ""
                      }`}
                      name="day"
                      value={birthday.day ?? ""}
                      onChange={handleChangeBirthday}
                      required
                      style={{
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="" disabled>
                        Day
                      </option>
                      {renderDays()}
                    </select>
                  </label>

                  <span className="mx-1">/</span>

                  <label>
                    <select
                      className={`form-control ${
                        errorIndicator === "dateOfBirth"
                          ? "border border-danger"
                          : ""
                      }`}
                      name="month"
                      value={birthday.month ?? ""}
                      onChange={handleChangeBirthday}
                      required
                      style={{
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="" disabled>
                        Month
                      </option>
                      {renderMonths()}
                    </select>
                  </label>

                  <span className="mx-1">/</span>

                  <label>
                    <select
                      className={`form-control ${
                        errorIndicator === "dateOfBirth"
                          ? "border border-danger"
                          : ""
                      }`}
                      name="year"
                      value={birthday.year ?? ""}
                      onChange={handleChangeBirthday}
                      required
                      style={{
                        border: "1px solid #ced4da",
                      }}
                    >
                      <option value="" disabled>
                        Year
                      </option>
                      {renderYears()}
                    </select>
                  </label>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="gender">Gender</label>
                <select
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "gender" ? "border border-danger" : ""
                  }`}
                  id="gender"
                  name="gender"
                  value={userInfo.gender ?? "male"}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label htmlFor="address">Address</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "address" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="address"
                  value={userInfo.address ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="zipCode">Zip Code</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "zipCode" ? "border border-danger" : ""
                  }`}
                  type="text"
                  name="zipCode"
                  value={userInfo.zipCode ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label htmlFor="countryCode">Country</label>
                <select
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "countryCode"
                      ? "border border-danger"
                      : ""
                  }`}
                  id="gender"
                  name="countryCode"
                  value={userInfo.countryCode ?? ""}
                >
                  <option disabled>SELECT COUNTRY</option>
                  {countries &&
                    countries.map((data, index) => (
                      <option value={data.alpha3} key={index}>
                        {data.country}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-10 col-md-10">
                <label htmlFor="practice">Facility</label>

                {facilities.length > 0 ? (
                  <select
                    onChange={handleChange}
                    className={`form-control ${
                      errorIndicator === "facility"
                        ? "border border-danger"
                        : ""
                    }`}
                    id="facility"
                    name="facility"
                    value={userInfo.facility ?? ""}
                  >
                    <option disabled>SELECT Facility</option>
                    {facilities.map((facility, index) => (
                      <option value={facility.id} key={index}>
                        {facility.longName}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner
                      className="spinner-border text-white text-center"
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor: "#f36917",
                      }}
                    ></Spinner>
                  </div>
                )}
              </div>
              <div
                className="col-sm-2 col-md-2"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <button style={{ height: "38px" }} className="btn btn-primary">
                  Add Facility
                </button>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-sm-12 col-md-6">
                <label htmlFor="practice">Practice</label>
                <select
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "practice" ? "border border-danger" : ""
                  }`}
                  id="practice"
                  name="practice"
                  value={userInfo.practice ?? ""}
                >
                  <option disabled>SELECT PRACTICE</option>
                  {practices &&
                    practices.map((data, index) => (
                      <option value={data._id} key={index}>
                        {data.label.charAt(0).toUpperCase() +
                          data.label.slice(1)}
                      </option>
                    ))}
                </select>
              </div> */}
              <div className="col-sm-12 col-md-6">
                <label htmlFor="email">Email</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "email" ? "border border-danger" : ""
                  }`}
                  type="email"
                  name="email"
                  value={userInfo.email.toLowerCase() ?? ""}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <label htmlFor="password">Password</label>
                <input
                  onChange={handleChange}
                  className={`form-control ${
                    errorIndicator === "password" ? "border border-danger" : ""
                  }`}
                  type="password"
                  name="password"
                  value={userInfo.password ?? ""}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`form-control ${
                    errorIndicator === "confirmPassword"
                      ? "border border-danger"
                      : ""
                  }`}
                  type="password"
                  name="confirmPassword"
                />
              </div>
            </div>

            <button
              className="btn btn-primary mt-3 w-100 py-3"
              onClick={handleSubmit}
            >
              Register Carehome Manager
            </button>
            {/* <NavLink
              className="text-center d-block mt-2"
              to="/login"
              onClick={() => {
                setErrorMessage("");
                setErrorIndicator("");
                setSuccessMessage("");
              }}
            >
              Go back to login
            </NavLink> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterCareHome;
