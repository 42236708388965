import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBOruYoVCVW9AnE-9GA1KTSbhvrnDrTgMU",
  authDomain: "hapi-healthcare-chat.firebaseapp.com",
  databaseURL:
    "https://hapi-healthcare-chat-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hapi-healthcare-chat",
  storageBucket: "hapi-healthcare-chat.appspot.com",
  messagingSenderId: "1061820464741",
  appId: "1:1061820464741:web:0a1bc136eb6217cb0a908c",
  measurementId: "G-VDT88QKPDH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  console.log(permission);

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BDfbfeqR8CIU_lvGJV2ikA5KIUHF-c-kO82VRmy3sjpFlVB2NAEstyg2dETwV8AAxgn_TjwReRafGQ4xh1NW3jA",
    });
    console.log(token);
  }
};
